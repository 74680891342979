'use client';

import { ReactNode, createContext, useContext, useState } from 'react';

interface PwaContextType {
	promptEvent: any;
	setPromptEvent: (arg: any) => void;
	isPwaInstalled: boolean;
	setIsPwaInstalled: (arg: boolean) => void;
}

// Create the context with default values
const PwaContext = createContext<PwaContextType>({
	promptEvent: null,
	setPromptEvent: () => {},
	isPwaInstalled: false,
	setIsPwaInstalled: () => {},
});

export const PwaContextProvider = ({ children }: { children: ReactNode }) => {
	const [promptEvent, setPromptEvent] = useState<any>(null);
	const [isPwaInstalled, setIsPwaInstalled] = useState(false);

	return (
		<PwaContext.Provider
			value={{
				promptEvent,
				setPromptEvent,
				isPwaInstalled,
				setIsPwaInstalled,
			}}
		>
			{children}
		</PwaContext.Provider>
	);
};

export const usePwaContext = () => {
	const context = useContext(PwaContext);
	if (!context) throw new Error('No PwaContextProvider found');
	return context;
};
