'use client';

import React, { createContext, useContext, useState } from 'react';

interface ModalContextType {
	currentModal: string;
	openModal: (modalId: string, onOpen?: () => void) => void;
	closeModal: (onClose?: () => void) => void;
}

// Create the context with default values
const ModalContext = createContext<ModalContextType>({
	currentModal: '',
	openModal: () => {},
	closeModal: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
	const [currentModal, setCurrentModal] = useState('');

	const openModal = (modalId: string, onOpen?: () => void) => {
		setCurrentModal(modalId);
		if (typeof onOpen === 'function') {
			onOpen();
		}
	};

	const closeModal = (onClose?: () => void) => {
		if (typeof onClose === 'function') {
			onClose();
		}
		setCurrentModal('');
	};

	return (
		<ModalContext.Provider
			value={{
				currentModal,
				openModal,
				closeModal,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => {
	const context = useContext(ModalContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}

	return context;
};
