'use client';

export default function ScreenSizeInspector() {
	if (['development', 'production'].includes(process.env.NEXT_PUBLIC_ENV || '')) return null;
	return (
		<div className="fixed top-0 z-50 h-4 w-12 bg-warning text-center text-xs text-white">
			<div className="xxs:hidden">{'< XXS'}</div>
			<div className="hidden xxs:block xs:hidden">XXS</div>
			<div className="hidden xs:block sm:hidden">XS</div>
			<div className="hidden sm:block md:hidden">SM</div>
			<div className="hidden md:block lg:hidden">MD</div>
			<div className="hidden lg:block xl:hidden">LG</div>
			<div className="hidden xl:block">XL+</div>
		</div>
	);
}
